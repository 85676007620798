import state from './moduleProductPriceRulesState.js'
import mutations from './moduleProductPriceRulesMutations.js'
import actions from './moduleProductPriceRulesActions.js'
import getters from './moduleProductPriceRulesGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}