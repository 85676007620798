import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth/moduleAuth.js'
import user from './user/moduleUser.js'
import order from './orders/moduleOrders.js'
import shipping from './shipping/moduleShipping.js'
import creditTerms from './credit-terms/moduleCreditTerms.js'
import offers from './offers/moduleOffer.js'
import catalog from './catalog/moduleProducts.js'
import productPriceRules from './product-price-rules/moduleProductPriceRules.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    user,
    shipping,
    order,
    creditTerms,
    offers,
    catalog,
    productPriceRules
  },
  strict: process.env.DEV,
})
