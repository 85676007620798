export default {

    SET_USER_DETAILS(state, userDetails){
        state.userData = userDetails;
    },

    SET_SUPPLIER_COMPANY_DETAILS(state, supplierCompanyDetails){
        state.supplierCompanyDetails = supplierCompanyDetails;
    },

    SET_SUPPLIER_DETAILS(state, supplierDetails){
        state.supplierDetails = supplierDetails;
    }
}