import backend_api from "@/http/requests/backend-api"

export default {

  getOrders({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getOrders()
        .then(response => { 
          commit('SET_ORDERS', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },

  getOrder({ commit }, id){
    return new Promise((resolve, reject) => {
      backend_api.getOrder(id)
        .then(response => { 
          resolve(response.data.result[0])
        })
        .catch(error => reject(error))
    })
  },

  editOrder({ commit }, orderData){
    return new Promise((resolve, reject) => {
      let id = orderData.id;
      let payload = {
        'order_data': {
          'state': orderData.state
        }
      }

      backend_api.editOrder(id, payload)
        .then(response => { 
          let order = response.data.result[0];
          commit('SET_ORDER_EDIT', order)
          resolve(order)
        })
        .catch(error => reject(error))
      })
      
  }

}