export const initialAbility = [
  {
    action: 'read',
    subject: 'publicPage',
  },
]

export const supplierAbility = [
  {
    action: 'manage',
    subject: 'all',
  },
]

export const adminAbility = [
  {
    action: 'manage',
    subject: 'all',
  },
]

export const _ = undefined
