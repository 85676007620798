export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/user-details',
    name: 'user-details',
    component: () => import('@/views/restock/UserDetails.vue'),
    meta: {
      pageTitle: 'userDetails',
    },
  },

  {
    path: '/shipping-info',
    name: 'shipping-info',
    component: () => import('@/views/restock/ShippingInfo.vue'),
    meta: {
      pageTitle: 'shippingInfo',
    },
  },

  {
    path: '/company-info',
    name: 'company-info',
    component: () => import('@/views/restock/CompanyInfo.vue'),
    meta: {
      pageTitle: 'companyInfo',
    },
  },
]
