import backend_api from "@/http/requests/backend-api/index.js"

export default {

  getProductPriceRules({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getProductPriceRules()
        .then(response => {
          commit('SET_PRODUCT_PRICE_RULES', response.data.result)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getProductPriceRule({ commit }, id){
    return new Promise((resolve, reject) => {
      backend_api.getProductPriceRule(id)
        .then(response => { 
          resolve(response.data.result)
        })
        .catch((error) => { reject(error) })
    })
  },

  deleteOffer({ commit }, id){
    return new Promise((resolve, reject) => {
      backend_api.deleteProductPriceRule(id)
        .then(response => {
          commit('DELETE_OFFER', id)
          resolve(response.data.result)
        })
        .catch((error) => { reject(error) })
    })
  },

  deletePricelist({ commit }, id){
    return new Promise((resolve, reject) => {
      backend_api.deleteProductPriceRule(id)
        .then(response => {
          commit('DELETE_PRICELIST', id)
          resolve(response.data.result)
        })
        .catch((error) => { reject(error) })
    })
  }

}