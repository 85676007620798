export default {

    order: (state) => (orderId) => {
        return state.orders.find(order => order.id == orderId);
    },

    filteredOrders: (state) => (ordersFilters) => {
        let filteredOrders = state.orders;
        if(ordersFilters.statuses != null && ordersFilters.statuses.length > 0){
            filteredOrders = filteredOrders.filter((order) => {
                return ordersFilters.statuses.indexOf(order.state) != -1
            });
        }
        if(ordersFilters.fromDate){
            let date_timestamp = new Date(ordersFilters.fromDate).getTime() / 1000;
            filteredOrders = filteredOrders.filter((order) => {
                return date_timestamp <= order.date_timestamp
            });
        }
        if(ordersFilters.toDate){
            let date_timestamp = (new Date(ordersFilters.toDate).getTime() / 1000) + 86400;
            filteredOrders = filteredOrders.filter((order) => {
                return date_timestamp >= order.date_timestamp
            });
        }
        return filteredOrders
    }

}