import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
//import apps from './routes/apps'
//import dashboard from './routes/dashboard'
//import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
//import chartsMaps from './routes/charts-maps'
//import formsTable from './routes/forms-tables'
import others from './routes/others'
import sales from './routes/sales'
import marketing from './routes/marketing'
import catalog from './routes/catalog'
import ability from '@/libs/acl/ability'
import { supplierAbility } from '@/libs/acl/config'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'sales-orders' } },
    //...apps,
    //...dashboard,
    ...pages, //contains login page
    //...chartsMaps,
    //...formsTable,
    //...uiElements,
    ...others,
    //...marketing,
    ...catalog,
    ...sales,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

import store from '@/store'

router.beforeEach((to, from, next) => {

  if(to.meta.redirectIfLoggedIn){
    store.dispatch('auth/isUserLoggedIn').then((isLoggedIn) => {
        if(isLoggedIn){
            next({path: '/'})
        }
        else{
            next()
        }
    })
  }
  
  if (!canNavigate(to)) {
    //abilities are lost after refresh, so let's log user back in and update them, if needed     
    store.dispatch('auth/isUserLoggedIn').then((isLoggedIn) => {
      if (isLoggedIn) {
        //check if user has suppliers, otherwise he's not a supplier and shouldn't have access, even if logged in
        store.dispatch('user/getSupplierDetails')
          .then(() => {
            ability.update(supplierAbility)
            if(canNavigate(to)){
                next()
            }
            else{
                //return next({ name: 'misc-not-authorized' })
                return next({ name: 'not-authorized' })
            }
          })
          .catch((error) => {
            //return next({ name: 'misc-not-authorized' })
            return next({ name: 'not-authorized' })
          })
      } else {
            store.dispatch('auth/logout')     
                        
      }
    })
  } else {
       next()
   }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
