export default {

    SET_ORDERS(state, orders) {
        state.orders = orders;
    },

    SET_ORDER_EDIT(state, order){
        for(let i = 0;i < state.orders.length; i++){
            if(state.orders[i].id == order.id){
                state.orders[i] = order;
                break;
            }
        }
    },
}