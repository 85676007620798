export default {

    offer: (state) => (offerId) => {
      return state.productPriceRules.offers.find(offer => offer.id == offerId)
    },
  
    pricelist: (state) => (pricelistId) => {
      return state.productPriceRules.pricelists.find(pricelist => pricelist.id == pricelistId)
    },
  
    offers: (state) => {
      return state.productPriceRules.offers
    },
  
    pricelists: (state) => {
      return state.productPriceRules.pricelists
    }
  
  }