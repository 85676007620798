/*= ========================================================================================
  File Name: moduleUserActions.js
  Description: User Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import backend_api from '@/http/requests/backend-api'

export default {

  getUserDetails({ commit }) {
    return new Promise((resolve, reject) => {
      backend_api.getUserDetails()
        .then(response => {
          const userDetails = response.data.result
          commit('SET_USER_DETAILS', userDetails)
          resolve(userDetails)
        })
        .catch(error => reject(error))
    })
  },

  editUserDetails({ commit }, userDetails) {
    const payload = {
      customer: {
        email: userDetails.email,
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
      },
    }
    return new Promise((resolve, reject) => {
      backend_api.editUserDetails(payload)
        .then(response => {
          const userDetails = response.data.result
          commit('SET_USER_DETAILS', userDetails)
          resolve(userDetails)
        })
        .catch(error => reject(error))
    })
  },

  changePassword(_, passwords) {
    return new Promise((resolve, reject) => {
      backend_api.changePassword(passwords)
        .then(response => {
          const res = response.data.result
          resolve(res)
        })
        .catch(error => reject(error))
    })
  },

  getSupplierDetails({ commit }, supplierId) {
    return new Promise((resolve, reject) => {
      backend_api.getSupplierDetails(supplierId)
        .then(response => {
          const supplierDetails = response.data.result[0]
          commit('SET_SUPPLIER_DETAILS', supplierDetails)
          resolve(supplierDetails)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  editSupplierDetails({ commit }, supplierDetails) {
    const supplierId = supplierDetails.id
    const payload = {
      supplier: {
        shipping_terms: supplierDetails.shipping_terms,
        shipping_everywhere: false,
        includedStates: supplierDetails.shipping_states,
        includedAreas: [],
        includedPostcodes: [],
        excludedStates: [],
        excludedAreas: [],
        excludedPostcodes: supplierDetails.shipping_postal_codes,
      },
    }
    return new Promise((resolve, reject) => {
      backend_api.editSupplierDetails(supplierId, payload)
        .then(response => {
          const supplierDetails = response.data.result[0]
          commit('SET_SUPPLIER_DETAILS', supplierDetails)
          resolve(supplierDetails)
        })
        .catch(error => { reject(error) })
    })
  },

  getSupplierCompanyDetails({ commit }, supplierId) {
    return new Promise((resolve, reject) => {
      backend_api.getSupplierCompanyDetails(supplierId)
        .then(response => {
          const supplierCompanyDetails = response.data.result[0]
          commit('SET_SUPPLIER_COMPANY_DETAILS', supplierCompanyDetails)
          resolve(supplierCompanyDetails)
        })
        .catch(error => { reject(error) })
    })
  },

  updateSupplierCompanyDetails({ commit }, supplierId) {
    return new Promise((resolve, reject) => {
      backend_api.updateSupplierCompanyDetails(supplierId)
        .then(response => {
          const supplierCompanyDetails = response.data.result[0]
          commit('SET_SUPPLIER_COMPANY_DETAILS', supplierCompanyDetails)
          resolve(supplierCompanyDetails)
        })
        .catch(error => { reject(error) })
    })
  },

}
