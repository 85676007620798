import backend_api from "@/http/requests/backend-api"

export default {

  editProduct({ commit }, product ){
    let payload = {
      'product': {
        'price': product.price,
        'active': product.active
      }
    }
    
    return new Promise((resolve, reject) => {
      backend_api.editProduct(payload, product.id)
        .then(response => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}