//import axios from "@/http/axios/index.js"
import axios from '@axios'
import store from '@/store'


const headers = {
  'Content-Type': 'application/json'
}

const domain = process.env.VUE_APP_API_DOMAIN

export default {
  getUserDetails() {
    return axios.get(domain + "/bridge/user/me", {headers: headers})
  },

  editUserDetails(payload) {
    return axios.post(domain + "/bridge/user/me", payload, {headers: headers})
  },

  changePassword(payload) {
    return axios.post(domain + "/client/user/password/change", payload, {headers: headers})
  },

  getSupplierDetails(supplierId){
    if (0) {
      return axios.get(domain + "/admin/suppliers/" + supplierId, {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id, {headers: headers})
  },

  editSupplierDetails(supplierId, payload){
    if (0) {
      return axios.put(domain + "/admin/suppliers/" + supplierId, payload, {headers: headers})
    }
    return axios.put(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id, payload, {headers: headers})
  },

  getSupplierCompanyDetails(supplierId){
    if (0) {
      return axios.get(domain + "/admin/suppliers/" + supplierId + '/companies', {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + '/companies', {headers: headers})
  },

  updateSupplierCompanyDetails(supplierId){
    if (0) {
      return axios.put(domain + "/admin/suppliers/" + supplierId + '/companies/update', {headers: headers})
    }
    return axios.put(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + '/companies/update', {headers: headers})
  },

  getCompanies() {
    return axios.get(domain + "/admin/admin/companies", {headers: headers})
  },

  getOrder(id) {
    if (0) {
      return axios.get(domain + "/admin/orders/" + id, {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/orders/" + id, {headers: headers})
  },

  editOrder(id, payload) {
    if (0) {
      return axios.put(domain + "/admin/orders/" + id, payload, {headers: headers})
    }
    return axios.put(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/orders/" + id, payload, {headers: headers})
  },

  getOrders() {
    if (0) {
      return axios.get(domain + "/admin/orders", {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/orders", {headers: headers})
  },

  getCreditTerms() {
    if (0) {
      return axios.get(domain + "/admin/credit-terms", {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/credit-terms", {headers: headers})
  },

  updateCreditTerms(payload) {
    if (0) {
      return axios.put(domain + "/admin/credit-terms", payload, {headers: headers})
    }
    return axios.put(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/credit-terms", payload, {headers: headers})
  },

  getProducts() {
    if (0) {
      return axios.get(domain + "/admin/products", {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/products", {headers: headers})
  },

  editProduct(payload, product_id) {
    return axios.put(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/product/" + product_id, payload, {headers: headers})
  },

  getProductCategories(){
    return axios.get(domain + "/bridge/supplier/product-categories", {headers: headers});
  },

  getPostalCodes() {
    return axios.get(domain + "/bridge/supplier/postal-codes", {headers: headers});
  },

  getAreas() {
    return axios.get(domain + "/bridge/supplier/states", {headers: headers});
  },

  getProductPriceRule(id){
    if (0) {
      return axios.get(domain + "/admin/product-price-rules/" + id, {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/product-price-rules/" + id, {headers: headers})
  },

  deleteProductPriceRule(id){
    if (0) {
      return axios.delete(domain + "/admin/product-price-rules/" + id, {headers: headers})
    }
    return axios.delete(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/product-price-rules/" + id, {headers: headers})
  },

  createProductPriceRule(payload){
    if (0) {
      return axios.post(domain + "/admin/product-price-rules", payload, {headers: headers})
    }
    return axios.post(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/product-price-rules", payload, {headers: headers})
  },

  editProductPriceRule(id, payload){
    if (0) {
      return axios.put(domain + "/admin/product-price-rules/" + id, payload, {headers: headers})
    }
    return axios.put(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/product-price-rules/" + id, payload, {headers: headers})
  },

  getProductPriceRules(){
    if (0) {
      return axios.get(domain + "/admin/product-price-rules", {headers: headers})
    }
    return axios.get(domain + "/client/suppliers/" + store.state.user.userData.suppliers[0].id + "/product-price-rules", {headers: headers})
  }

}