import state from './moduleOrdersState.js'
import mutations from './moduleOrdersMutations.js'
import actions from './moduleOrdersActions.js'
import getters from './moduleOrdersGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}