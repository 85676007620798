/*=========================================================================================
  File Name: moduleDataList.js
  Description: Credit Terms Data List Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: George Symeonidis
==========================================================================================*/

import backend_api from "@/http/requests/backend-api"

const CREDIT_TERM_STATUS_APPROVED = "approved";
const CREDIT_TERM_STATUS_REJECTED = "rejected";

export default {
  
  getCreditTerms({ commit }) {
    return new Promise((resolve, reject) => {
      backend_api.getCreditTerms()
        .then(response => { 
          commit('SET_CREDIT_TERMS', response.data.result)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  approveCreditTerms({ commit }, ids) {
    return new Promise((resolve, reject) => {
      let creditTerms = ids.map(item => {
        return {"id": item, "status": CREDIT_TERM_STATUS_APPROVED}
      });
      let payload = {
        "credit_terms": creditTerms
      };
      backend_api.updateCreditTerms(payload)
        .then(response => {
          creditTerms.forEach(item => commit('UPDATE_CREDIT_TERM', item));
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  rejectCreditTerms({ commit }, ids) {
    return new Promise((resolve, reject) => {
      let creditTerms = ids.map(item => {
        return {"id": item, "status": CREDIT_TERM_STATUS_REJECTED}
      });
      let payload = {
        "credit_terms": creditTerms
      };
      backend_api.updateCreditTerms(payload)
        .then(response => {
          creditTerms.forEach(item => commit('UPDATE_CREDIT_TERM', item));
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
  
}