export default {

    SET_PRODUCT_PRICE_RULES(state, productPriceRules) {
      state.productPriceRules.offers = productPriceRules.offers;
      state.productPriceRules.pricelists = productPriceRules.pricelists;
    },
  
    DELETE_OFFER(state, offerId){
      let pos = state.productPriceRules.offers.findIndex(offer => offer.id == offerId)
      state.productPriceRules.offers.splice(pos, 1)
    },
  
    DELETE_PRICELIST(state, pricelistId){
      let pos = state.productPriceRules.pricelists.findIndex(pricelist => pricelist.id == pricelistId)
      state.productPriceRules.pricelists.splice(pos, 1)
    }
  
  }