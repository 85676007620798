import backend_api from "@/http/requests/backend-api"

export default {
  getStates({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getAreas()
        .then(response => { 
          commit('SET_AREAS', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
 
  getPostalCodes({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getPostalCodes()
        .then(response => { 
          commit('SET_POSTAL_CODES', response.data.result)
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
}