export default {

    getProducts: (state) => {
      return state.products
    },
  
    getProductCategories: (state) => {
      return state.productCategories
    },
  
    getPostalCodes: (state) => {
      return state.postalCodes
    },
  
    getAreas: (state) => {
      return state.areas
    } 
    
  }