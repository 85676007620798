export default {
    ADD_ITEM(state, item) {
      state.products.unshift(item)
    },
    SET_CREDIT_TERMS(state, creditTerms) {
      state.creditTerms = creditTerms
    },
    UPDATE_CREDIT_TERM(state, creditTerm){
      let stateCreditTerm = state.creditTerms.find(item => item.id == creditTerm.id);
      if(stateCreditTerm != "undefined"){
        stateCreditTerm.status = creditTerm.status;
      }
      else{
        this.ADD_ITEM(state, creditTerm);
      }
    }
  }
  