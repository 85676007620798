import backend_api from "@/http/requests/backend-api"

export default {

  getProducts({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getProducts()
        .then(response => {
          commit('SET_PRODUCTS', response.data.result.items)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getProductCategories({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getProductCategories()
        .then(response => {
          commit('SET_PRODUCT_CATEGORIES', response.data.result)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getPostalCodes({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getPostalCodes()
        .then(response => {
          commit('SET_POSTAL_CODES', response.data.result)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getAreas({ commit }){
    return new Promise((resolve, reject) => {
      backend_api.getAreas()
        .then(response => {
          commit('SET_AREAS', response.data.result)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}