import state from './moduleCreditTermsState.js'
import mutations from './moduleCreditTermsMutations.js'
import actions from './moduleCreditTermsActions.js'
import getters from './moduleCreditTermsGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}