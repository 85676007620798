/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "@/auth/jwt/useJwt"

import VueCookies from 'vue-cookies'
import ability from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import  useJwt from '@/auth/jwt/useJwt.js'
import store from '@/store'
import router from '@/router'

export default {
     // JWT
    loginJWT({ commit, dispatch }, payload) {
      return new Promise((resolve,reject) => {
        jwt.login({username: payload.userDetails.email, password: payload.userDetails.password})
          .then(response => {

            // If there's user data in response
            if(response.data.meta) {
              commit("SET_ACCESS_TOKEN", response.data.result)
              commit("SET_BEARER", response.data.result)
              localStorage.setItem("accessToken", response.data.result)
              localStorage.setItem("refreshToken", response.data.meta.refreshToken)
              localStorage.setItem("rememberMe", payload.checkbox_remember_me ? 1 : 0)
              resolve()
            }else {
              reject({message: "Wrong Email or Password"})
            }

          })
          .catch(error => { reject(error) })
      })
    },

    isUserLoggedIn({commit, dispatch}) {
      
      let accessTokenFromStorage = localStorage.getItem('accessToken')
      let accessTokenFromMemory = store.getters['auth/accessToken']
      let fromLoginCookie = $cookies.get('fromLogin')
      let fromLogin = fromLoginCookie ? parseInt(fromLoginCookie) === 1 : false
      if(!accessTokenFromStorage){
        return false
      }
      if(!accessTokenFromMemory){
          if(!fromLogin && !store.state.auth.isRememberMeEnabled()){
              return false
          }
      }  
      commit("SET_BEARER", accessTokenFromStorage)  
      return dispatch('user/getUserDetails', null, {root:true})
        .then(userData => {
          commit("SET_ACCESS_TOKEN", accessTokenFromStorage)
          return true
        })
        .catch((error) => {
              return false
        })
    },

    // isUserStillLoggedIn({commit, dispatch}) {
    //   let accessTokenFromStorage = localStorage.getItem('accessToken')
    //   let accessTokenFromMemory = store.getters['auth/accessToken']
      
    //    if(!accessTokenFromStorage){
    //      return false           
    //    }
    //    else {
    //     commit("SET_BEARER", accessTokenFromStorage)  
    //     return dispatch('user/getUserDetails', null, {root:true})
    //       .then(userData => {
    //         commit("SET_ACCESS_TOKEN", accessTokenFromStorage)
    //         return true
    //       })
    //       .catch(() => {
    //         return dispatch('fetchAccessToken')
    //           .then(() => {
    //             return true 
    //           })
    //           .catch(() => {
    //             return false
    //           })
    //       })
    //    }
    // },

    fetchAccessToken({commit, getters}) {
      return new Promise((resolve, reject) => {
        jwt.refreshToken()
          .then(response => {
            commit("SET_ACCESS_TOKEN", response.data.result)
            commit("SET_BEARER", response.data.result)
            localStorage.setItem("accessToken", response.data.result)
            localStorage.setItem("refreshToken", response.data.meta.refreshToken)
            resolve(response.data.result) 
          }).catch((error) => {
            reject(error)
          });
      })
    },

    logout({commit}){// }, {$acl, $vs}){
      return new Promise((resolve, reject) => {

        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem("rememberMe")
 
        // Remove userData from localStorage
        localStorage.removeItem('userData')  

        // Reset ability
        ability.update(initialAbility)

        commit('SET_ACCESS_TOKEN', null)
        commit('SET_BEARER', null)
        VueCookies.remove('fromLogin')

        window.location.href = '/login'
        //router.push({ name: 'auth-login'})
      })
    }
}
