export default {

    SET_PRODUCTS(state, products) {
      state.products = products;
    },
  
    SET_PRODUCT_CATEGORIES(state, productCategories) {
      state.productCategories = productCategories;
    },
  
    SET_POSTAL_CODES(state, postalCodes) {
      state.postalCodes = postalCodes;
    },
    
    SET_AREAS(state, areas){
      state.areas = areas
    }
  
  }