export default [
  {
    path: '/sales/orders',
    name: 'sales-orders',
    component: () => import('@/views/restock/sales/orders/OrdersList.vue'),
    meta: {
      pageTitle: 'Orders',
      filters: {
        sidebarId: 'orders-filters-sidebar',
      },
      refresh : true,
    },
  },
  {
    path: '/sales/order/:id',
    name: 'sales-order',
    component: () => import('@/views/restock/sales/orders/SingleOrder.vue'),
    meta: {
      pageTitle: 'Order',
    },
  },
  
  {
    path: '/sales/credit-terms',
    name: 'sales-credit-terms',
    component: () => import('@/views/restock/sales/credit_terms/CreditTermsList.vue'),
    meta: {
      pageTitle: 'CreditAgreements',
      refresh : true,
    },
  },


]
